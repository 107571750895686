import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Aside = makeShortcode("Aside");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Where did these workouts come from? Well…`}</p>
    </PageDescription>
    <h2>{`About Crossfit TheVille`}</h2>
    <Row mdxType="Row">
    <Column colMd={5} colLg={8} mdxType="Column">
    CrossFit the Ville is located at 721 East Main Street, Louisville KY. We are not your typical gym. We are a fitness facility. CrossFit strives to break away from the current, flawed fitness model we see so prevalent in America. You won’t find mirrors, fancy equipment, and a juice bar. What you will find are all the tools necessary to build elite levels of fitness. In most gyms, these tools are relegated to a dark, dusty corner; at CrossFit barbells, dumbbells, rowers, kettle bells, wall balls, rings, pull-up bars, and other “basic” and “old-school” fitness implements are cherished above all else.  Of course, the greatest key to your success is you. Achieving elite levels of fitness is a process that takes time, determination, and the watchful eye of our dedicated staff of coaches. Whatever your fitness level is today and whatever your fitness goals may be, we are there to help and guide you every step of the way. As with any endeavor you may choose to participate in, having fun is the glue that holds it all together. CrossFit has no peer in the ability to foster a sense of accomplishment and team building amongst it’s participants. Yes, you will be challenged within your limits, but we assure you, you’ll end every workout with a smile and high-five.
    </Column>
    <Column colMd={2} colLg={3} offsetMd={1} offsetLg={1} mdxType="Column">
    <Aside mdxType="Aside">
        <p>
        <strong>
            From Crossfit TheVille's about page
        </strong>
        </p> See <a href="https://crossfittheville.wordpress.com/about-us/"> the original</a>
    </Aside>
    </Column>
    </Row>
    <h2>{`The end of an era`}</h2>
    <p>{`CrossFit the Ville is now officially closed for business at 721 E Main St. We want to sincerely thank each and every member past and present for all the fond memories. Since starting CrossFit Louisville East in 2009 and CrossFit the Ville in 2011 thousands of members have graced our doors. We are honored to have been, and continue to be, a part of the CrossFit community in Louisville.`}</p>
    <p>{`I will now focus more on my Personal Training business, which I’ve been doing since 1997, but CrossFit will continue to play a role in my coaching as well as my personal workouts. If you have interest in personal training please email: `}<a parentName="p" {...{
        "href": "mailto:Daniel@crossfittheville.org"
      }}>{`Daniel@crossfittheville.org`}</a></p>
    <p>{`Stay fit Louisville!`}</p>
    <p>{`-Daniel`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      